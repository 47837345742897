<template>
  <div v-if="($store.state.fastPermissoes.administrador == 'S' || $store.state.fastPermissoes.professor == 'S') && $store.state.fastPermissoes.ativo == 'S'">
    <div class="fd-app-wrap">
      <div class="container">
        <!-- breadcrumb -->
        <nav>
          <ul class="fd-app-breadcrumb">
            <li class="fd-app-breadcrumb-item">
              <a
                href="/minhas-plataformas"
                class="aluno_font_color"
                @click.prevent="$router.push('/minhas-plataformas')"
              >Minhas Plataformas</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                href="#"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma)"
              >Plataforma {{ $store.state.fastPlataforma.nome_plataforma }}</a>
            </li>
            <li class="fd-app-breadcrumb-item">
              <a
                class="aluno_font_color"
                :href="'/plataforma/' + $route.params.id_plataforma + '/secretaria'"
                @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
              >Modo professor</a>
            </li>
            <li class="fd-app-breadcrumb-item active">
              <a
                class="aluno_font_color"
                href="#"
              >Aulas particulares</a>
            </li>
          </ul>
        </nav>
        <!-- /breadcrumb -->
      </div>

      <div class="fd-mode-adm-content mt-4">
        <div class="container">
          <div class="row">
            <div class="col-sm-12 col-md-12 fd-mode-adm-content-body col-lg-12 ml-0">
              <!-- fd-app-welcome -->
              <section class="fd-app-welcome text-left">
                <h1 class="aluno_font_color">
                  Aulas
                  <span>particulares</span>
                </h1>
                <img
                  :src="require('@/assets/images/form-effect-01.png')"
                  style="max-height: 35px"
                >
              </section>
              <!-- /fd-app-welcome -->
            </div>
          </div>
        </div>
        <!-- conteudo principal -->
        <section class="container">
          <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <h3>Aulas pendentes (3)</h3>
            </div>
            <div
              v-for="index in 3"
              :key="index"
              class="col-sm-12 col-md-12 col-lg-12 mb-2"
            >
              <div class="card mb-2 ">
                <div class="card-header bg-ligth text-dark">
                  <i class="fas fa-info-circle text-secondary mr-1" /> Recebida em 11/01/2020 11:55
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                      <div
                        class="mr-2"
                        :style="{
                          backgroundImage:
                            'url(' +
                            ajustaLinkImageUser('https://devapi.fastead.com/Files/img/273_professor_637474383000464518.png') +
                            ')',
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: '50%',
                        }"
                      /> Emerson Santos
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                      <div>
                        <h6 class="mb-0">
                          Disciplina
                        </h6>
                        <small>Desenvolvimento de sistemas</small>
                      </div>                      
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                      <div class="text-center">
                        <button
                          class="btn btn-sm btn-success mb-2"
                          @click="exibeModalAceitaAula()"
                        >
                          <i class="fas fa-check-circle fa2-x" /> Aceitar aula
                        </button>
                        <button
                          class="btn btn-sm btn-danger mb-2"
                          @click="exibeModalRejeitarAula()"
                        > 
                          <i class="fas fa-times-circle fa2-x" /> Recusar aula
                        </button>
                      </div>                      
                    </div>
                  </div>
                </div>
              </div>
            </div>    
          </div>
          <div class="row mt-4">
            <div class="col-sm-12 col-md-12 col-lg-12 mb-4">
              <h3>Aulas realizadas (3)</h3>
            </div>
            <div
              v-for="index in 3"
              :key="index"
              class="col-sm-12 col-md-12 col-lg-12 mb-2"
            >
              <div class="card mb-2 ">
                <div class="card-header bg-secondary text-white">
                  Realizada em 11/01/2020 11:55
                </div>
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                      <div
                        class="mr-2"
                        :style="{
                          backgroundImage:
                            'url(' +
                            ajustaLinkImageUser('https://devapi.fastead.com/Files/img/273_professor_637474383000464518.png') +
                            ')',
                          width: '60px',
                          height: '60px',
                          borderRadius: '50%',
                          backgroundSize: '100%',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: '50%',
                        }"
                      /> Emerson Santos
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-6 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                      <div>
                        <h6 class="mb-0">
                          Disciplina
                        </h6>
                        <small>Desenvolvimento de sistemas</small>
                      </div>                      
                    </div>
                    <div class="col-sm-12 col-md-12 col-lg-3 d-flex align-items-center justify-content-center mt-4 mt-md-0">
                      <div class="text-center">
                        <button class="btn btn-sm btn-secondary">
                          <b-icon-arrow-counterclockwise /> Enviar Feedback
                        </button>
                      </div>                      
                    </div>
                  </div>    
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- conteudo principal -->
        <div class="container">
          <div class="mt-5 mb-5">
            <a
              class="btn btn-red-hollow"
              :href="'/plataforma/' + $route.params.id_plataforma + '/professor'"
              @click.prevent="$router.push('/plataforma/' + $route.params.id_plataforma + '/professor')"
            >
              <b-icon-arrow-return-left />
              Voltar
            </a>
          </div>
        </div>
      </div>
    </div>
    <modal
      name="exibeModalAceitaAula"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Aceitar solicitação de aula</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('exibeModalAceitaAula')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="container card-body rounded">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <small>Descrição da aula solicitada:</small>
                    <textarea
                      class="form-control  mb-2"
                      cols="25"
                      rows="2"
                    />
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg-12 mb-2">
                    <small>Selecione o dia que deseja realizar a aula</small>
                    <select class="form-control">
                      <option value="">
                        Selecione a data
                      </option>
                    </select>
                  </div>
                  <div class="col-sm-12 col-md-12 col-lg mb-2 mt-4">
                    <button class="btn btn-sm btn-primary">
                      Aceitar aula
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>

    <modal
      name="exibeModalRejeitarAula"
      :shift-y="0.1"
      height="auto"
      :width="modalWidthSm"
      :scrollable="true"
      :adaptative="true"
    >
      <div class="row p-4">
        <div class="col-sm-12 col-md-12 col-lg-10 pl-4 pr-4">
          <h4>Recusar solicitação de aula</h4>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-2 pl-4 pr-4 text-right">
          <a
            class="btn btn-secondary"
            href="#"
            @click.prevent="hideModal('exibeModalRejeitarAula')"
          >
            <b-icon-arrow-return-left />
          </a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-12">
          <div class="row">
            <div class="col-sm-12 col-md-12 col-lg-12">
              <div class="container card-body rounded">
                <div class="row">
                  <div class="col-sm-12 col-md-12 col-lg mb-2">
                    <label>Deseja mesmo rejeitar essa aula?</label>
                    <button class="btn btn-sm btn-danger mt-4">
                      Rejeitar aula
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { settings } from "@/config";
import methods from "@/methods";

export default {
  name: "HomeInternoProfessorAulasParticulares",
  components: {
    
  },
  mixins: [methods],
  data: function () {
    return {
      modalWidthSm: this.isMobile() ? "80%" : "30%",
      modalWidthMd: this.isMobile() ? "90%" : "50%",
      modalWidthLg: this.isMobile() ? "100%" : "90%",
    };
  },
  mounted: function () {
    // Captura ID da plataforma pela URL
    if (this.$route.params.id_plataforma) {
      this.getFastDependecias(this.$route.params.id_plataforma, 'professor')
        .then(() => {
          this.$store.state.fastCarregando = false;
        })
        .catch((e) => {
          this.exibeToasty(e, "error");
          this.$store.state.fastCarregando = false;
        });
    }
  },
  methods: {
    exibeModalAceitaAula(){
        this.showModal("exibeModalAceitaAula")
    },
    exibeModalRejeitarAula(){
        this.showModal("exibeModalRejeitarAula")
    },
      
  },
};
</script>

<style></style>
